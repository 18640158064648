import React, { useState } from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Externalevents() {
  // const [] = useState()
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dataExternalEvents = [
    {
      des: `Rakshita Sridhar of Class V A and Vishantak Kumar of Class V C secured the
RUNNER-UP position in the Keynote category (PPT making) of ‘Z3NITH 2024’
event organised by Air Force Golden Jubilee Institute, Subroto Park.​`,
      // date: "9 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820242/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820242/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820242/pic3.jpg",
      ],
    },
    {
      des: `Surabhi Sameesh of Class II A bagged the FIRST position in the Matti Srijan
category of ‘Confluence 2024’ online event organised by DAV Public School,
Kailash Hills.​`,
      // date: "9 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820241/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/070820241/pic2.jpg",
      ],
    },
    {
      des: `Rayhaang Subba and Raghavendra Sagar of Class V C secured a special position in
the Galaxy Geeks category in the event ‘Abhivyakti’ organised by Gyan Mandir
Public School, Naraina Vihar.​`,
      date: "9 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic1.jpeg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic2.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/060820241/pic2.jpeg",
      ],
    },
    {
      des: `Augastya Tripathi of Class VII bagged the trophy of Zonal Rank-1, North in IFHO (Hindi Olympiad) conducted by International Olympiad Foundation-2023.​`,
      // date: "Saturday, 2 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic2.jpg",
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic5.jpg",
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic6.jpg",
      ],
    },
    {
      des: `Shreyas Loshali of Class VII bagged the trophy of Zonal Rank-1, North in IFCO (Computer Olympiad) conducted by International Olympiad Foundation-2023.​`,
      // date: "Saturday, 2 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic3.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic4.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic5.jpg",
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/230420242/pic6.jpg",
      ],
    },
    {
      des: `Vatsal Bhayani of class-VIII and Adyashree Pradhan of class-V participated in the Shreemad
      Bhagwatgeeta Shloka Uchcharan Abhivyanjana-2023 organized by GYAN BHARTI
      SCHOOL, SAKET, NEW DELHI and got First Position.​`,
      date: "Saturday, 2 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/31-aug-2023-1/Gyan-Bharati-School.jpg",
      ],
    },
    {
      des: `Satvik Malviya, Zayn Khan and Hardik Kumar of class XI participated in the Gaming
      competition BLACKOU1 organized by RAMJAS INTERNATIONAL SCHOOL, RK
      PURAM, NEW DELHI and got Second Position.​`,
      date: "9 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/16-aug-2023-1/pic1.jpeg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/16-aug-2023-1/pic2.jpeg",
      ],
    },
    {
      des: `Bhavya Gulati and Manuj Arora of Class IX secured the 2nd position in the Socio-Tech Masters category in the event "Confluence" organized by Air Force Golden Jubilee Institute, Subroto Park. ​`,
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/9-aug-2023-1/pic1.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/9-aug-2023-1/pic2.jpg",
      ],
    },

    {
      des: `Lasya Priya Yellasiri of Class VI, Shreyas Loshali of Class VII, and Aditya Kumar of Class VIII secured the 1st position in the Mathemagix category in the event 'Abhivyajana' organized by Gyan Bharati School, Saket.`,
      date: "22 July, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/9-aug-2023-2/pic1.jpg",
      ],
    },
    {
      des: `Mudit Ranjan, Ishant Ratan AND Aditya Rai of Class XII secured 1<sup>st</sup> postion in the Meme
      String Category in the event ‘ORDIN@TRIX23.0’ organized by Tagore International
      School, Vasant Vihar.`,
      date: "4 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-2/Image-2.jpg",
      ],
    },
    {
      des: `Shivansh of Class X AND Tanay Raj of Class IX Secured 1<sup>st</sup> position in the Meme String
      Category in the event "KRITI" organized by DAV Public School, Ashok Vihar.`,
      date: "4 May, 2023",
      images: [
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-2/Image-1.jpg",
      ],
    },
    {
      des: `The Mukhyamantri Vigyan Pratibha Pariksha for the students of class 9 th is meant to
      identify and nurture the talented students. The examination is conducted every year at
      state level. For the session 2022-23 two students ABHIJEET KUMAR (RANK-24,
      MARKS - 79%) and YASHAANSH DUBEY (RANK - 43, MARKS - 69.5%) of Chinmaya
      Vidyalaya made their place in merit list and are eligible for scholarship.`,
      images: [
        // "https://chinmayavvdelhi.ac.in/assets/images/awards/school-award-1-march-23.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-1/Abhijeet-Kumar.jpg",
        "https://chinmayavvdelhi.ac.in/assets/images/achievements/external-events/26-may-2023-1/Yashaansh-Dubey.jpg",
      ],
    },
  ];

  const school = (
    <div className="externalevents__container">
      {dataExternalEvents.map((item) => (
        <div className="externalevents__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="externalevents__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>
          {item.date && (
            <div className="externalevents__card__date">Date: {item.date}</div>
          )}
          <div
            className="externalevents__card__text"
            dangerouslySetInnerHTML={{ __html: item.des }}
          ></div>
        </div>
      ))}
    </div>
  );

  let uniformJSX = school;

  return (
    <Layout>
      <SEO
        title="Achivements - External Events | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="externalevents">
        <h1 className="heading">External Events</h1>

        {uniformJSX}
      </div>
    </Layout>
  );
}
